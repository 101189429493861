
















import Vue from 'vue';

import DnbButton from '@/components/DnbButton.vue';

export default Vue.extend({
  name: 'admin-button-group',
  components: { DnbButton },
  props: {
    name: { type: String, default: undefined },
    url: { type: String, default: undefined },
    create: { type: Boolean, default: false },
    save: { type: Function, default: undefined },
    deleteItem: { type: Function, default: undefined },
  },
});
