import {
  CreateTagDto,
  TagDto,
  UpdateTagDto,
} from '@portals/shared/admin/TagDto';

import { request } from './request';
export async function fetchTags(): Promise<TagDto[]> {
  const response = await request<TagDto[]>({
    url: 'tags',
  });
  return response.data;
}
export async function fetchTag(id: string): Promise<TagDto> {
  const response = await request<TagDto>({
    url: `tags/${id}`,
  });
  return response.data;
}
export async function createTag(data: CreateTagDto): Promise<TagDto> {
  const response = await request<TagDto>({
    url: `tags`,
    method: 'POST',
    data,
  });
  return response.data;
}
export async function updateTag(
  id: string,
  data: UpdateTagDto,
): Promise<TagDto> {
  const response = await request<TagDto>({
    url: `tags/${id}`,
    method: 'PATCH',
    data,
  });
  return response.data;
}
export async function deleteTag(id: string): Promise<void> {
  await request({
    url: `tags/${id}`,
    method: 'DELETE',
  });
}
