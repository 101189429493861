
































import { TagDto } from '@portals/shared/admin/TagDto';
import Vue from 'vue';

import AdminButtonGroup from '@/components/AdminButtonGroup.vue';
import AdminSection from '@/components/AdminSection.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbInput from '@/components/DnbInput.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import Loader from '@/components/Loader.vue';
import {
  createTag,
  deleteTag,
  fetchTag,
  updateTag,
} from '@/service/tagService';

import { Breadcrumb } from '../../models/Breadcrumb';

type Data = {
  loading: boolean;
  error: string | null;
  tag: TagDto;
  errors: Record<string, unknown>;
};

export default Vue.extend({
  name: 'edit-tag',
  components: {
    DnbInput,
    AdminSection,
    AdminButtonGroup,
    Breadcrumbs,
    DnbWarning,
    Loader,
  },
  props: {},
  data(): Data {
    return {
      loading: true,
      error: null,
      errors: {},
      tag: {
        id: '',
        title: '',
        slug: '',
        createdAt: '',
        updatedAt: '',
      },
    };
  },
  computed: {
    id(): string | undefined {
      return this.$route.params.id;
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          name: 'Tags',
          path: '/tags',
        },
        {
          name: this.tag.title || 'Create Tag',
        },
      ];
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchTag();
    } catch (error) {
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async fetchTag() {
      if (this.id) {
        this.tag = await fetchTag(this.id);
      }
    },
    async save() {
      this.error = null;
      this.errors = {};
      this.loading = true;

      try {
        if (this.id) {
          await updateTag(this.id, this.tag);
        } else {
          await createTag(this.tag);
        }
        this.$router.push('/tags');
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data.code === 'ValidationError'
        ) {
          this.errors = error.response.data.errors;
        } else {
          this.error = error.response?.data.message ?? error.message;
        }

        this.loading = false;
      }
    },
    async deleteTag() {
      if (this.id) {
        try {
          await deleteTag(this.id);
          this.$router.push('/tags');
        } catch (error) {
          this.error = error.message;
        }
      }
    },
  },
});
